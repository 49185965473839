<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">{{ $tc('role.title', 2) }}</h1>
    <Resource
        :index-request="Endpoints.index"
        :show-request="Endpoints.show"
        :create-request="Endpoints.create"
        :update-request="Endpoints.update"
        :delete-request="Endpoints.remove"
        :model-type="Role"
        :form-component="() => import('@/components/forms/RoleForm.vue')"
        :meta="{name: $tc('role.title', 1), namePlural: $tc('role.title', 2)}"
        :table-content="tableContent"
    />
  </v-container>
</template>

<script lang="js">
import Resource from '@/components/vuetifyResource/Resource.vue';
import eventBus from '@/eventBus';
import * as Endpoints from '@/api/endpoints/admin/role.js';
import Role from '@/application/models/admin/role.js';

export default {
  name: 'RoleTable',
  components: {
    Resource,
  },
  data() {
    return {
      Endpoints,
      Role,
    };
  },
  computed: {
    tableContent() {
      return [
        {
          text: this.$t('role.table.roleName'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name:  'roles' },
        text: this.$tc('role.title', 2),
      },
    ]);
  },
};
</script>
