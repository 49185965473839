import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { destroy, get, post, put } from '@/api/implementation/app';

export const autocomplete = (...args) => getPaginated(`admin/role/autocomplete`, ...args)

export const index = (...args) => getPaginated(`admin/role`, ...args);

export const show = (objectId) => get(`admin/role/${objectId}`);

export const create = (object) => post(`admin/role`, object);

export const update = (object) => put(`admin/role/${object.id}`, object);

export const remove = (objectId) => destroy(`admin/role/${objectId}`);
