import Model from '@/application/models/model.js';

export default class Role extends Model {
  id = 0;
  name = '';
  microservices = [];
  permissions = [];

  mapForRequest() {
    return {
      name: this.name,
      microservices: this.microservices.map(({ id }) => id),
      permissions: this.permissions,
    };
  }
}
